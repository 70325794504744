export const photoArray = [
  "https://i.imgur.com/iz2jrPl.jpg",
  "https://i.imgur.com/NlEKA0I.jpg",
  "https://i.imgur.com/kdhQvg1.jpg",
  "https://i.imgur.com/hXOiIB2.jpg",
  "https://i.imgur.com/exINGpf.jpg",
  "https://i.imgur.com/Os735wY.jpg",
  "https://i.imgur.com/bTgsAD3.jpg",
  "https://i.imgur.com/xoPIVDX.jpg",
  "https://i.imgur.com/kj2ja60.jpg",
  "https://i.imgur.com/IJJ3xNs.jpg",
  "https://i.imgur.com/cD6ADeG.jpg",
  "https://i.imgur.com/xCekvob.jpg",
  "https://i.imgur.com/15G0trF.jpg",
  "https://i.imgur.com/6uZX0U3.jpg",
  "https://i.imgur.com/lzR9aut.jpg",
  "https://i.imgur.com/SxiLPwl.jpg",
  "https://i.imgur.com/iQ5NMIv.jpg",
  "https://i.imgur.com/7NFQV2q.jpg",
  "https://i.imgur.com/7Es3jFZ.jpg",
  "https://i.imgur.com/CS0qtgf.jpg",
  "https://i.imgur.com/iBZ6xrq.jpg",
  "https://i.imgur.com/jxCiJYp.jpg",
  "https://i.imgur.com/MHfbygv.jpg",
  "https://i.imgur.com/kvwY2sF.jpg",
  "https://i.imgur.com/kgbKh2s.jpg",
  "https://i.imgur.com/DxVMYyM.jpg",
  "https://i.imgur.com/CI6zELl.jpg",
  "https://i.imgur.com/jPgnmkD.jpg",
  "https://i.imgur.com/knIyywL.jpg",
  "https://i.imgur.com/J7jmjwT.jpg",
  "https://i.imgur.com/W6iJ6IH.jpg",
  "https://i.imgur.com/NoscLhi.jpg",
  "https://i.imgur.com/iC8sjwj.jpg",
  "https://i.imgur.com/NV941rm.jpg",
  "https://i.imgur.com/XMznLOT.jpg",
  "https://i.imgur.com/PBmKQpt.jpg",
  "https://i.imgur.com/R0cwP3X.jpg",
  "https://i.imgur.com/pLzlzj0.jpg",
  "https://i.imgur.com/mSTAQFV.jpg",
  "https://i.imgur.com/jCRgmVO.jpg",
  "https://i.imgur.com/7myJnnX.jpg",
  "https://i.imgur.com/NC8SxFp.jpg",
  "https://i.imgur.com/Qm2kSvM.jpg",
  "https://i.imgur.com/J7dLEF8.jpg",
  "https://i.imgur.com/Iksh8sK.jpg",
  "https://i.imgur.com/ZG4DJls.jpg",
  "https://i.imgur.com/ZnHkGKh.jpg",
  "https://i.imgur.com/I9V9Qff.jpg",
  "https://i.imgur.com/NlAKvNG.jpg",
  "https://i.imgur.com/JEiWtk4.jpg",
  "https://i.imgur.com/WPyktjN.jpg",
  "https://i.imgur.com/lib9Mdq.jpg",
  "https://i.imgur.com/qpoAJIz.jpg",
  "https://i.imgur.com/QfSh8Zx.jpg",
  "https://i.imgur.com/7gSjOfA.jpg",
];
